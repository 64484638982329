import {BOOKINGS_DEF_ID, PageId} from '../constants';

export async function addBookingsPagesAsPanel(editorSDK, appToken) {
  const bookingsTpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId(appToken, BOOKINGS_DEF_ID)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll();
  const bookingsPages = allSitePages.filter(page => page.tpaApplicationId === bookingsTpaApplicationId && page.tpaPageId !== PageId.BOOKINGS_MEMBERS_AREA);
  return Promise.all(bookingsPages.map(bookingPage => editorSDK.pages.data.update(appToken, {pageRef: {id: bookingPage.id, type: bookingPage.type}, data: {managingAppDefId: BOOKINGS_DEF_ID}})));
}

export async function createBookCheckoutState(editorSDK, appToken) {
  const allSitePages = await editorSDK.pages.data.getAll();
  const bookCheckoutPageData = allSitePages.find(page => page.tpaPageId === PageId.BOOKINGS_CHECKOUT);
  if (bookCheckoutPageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookCheckoutPage: [{id: bookCheckoutPageData.id}]
      }
    })
  }
}
